import { useEffect, useState } from "react";
import { Trow } from "../components/table-element";
import { Thead } from "../components/table-head";
import classes from "../css/Table.module.css";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import validateResponse from "../../utils/requestValidator";

const json_url = process.env.REACT_APP_FETCHING_SERVER;

export default function Invoices() {
  const [invoices, setInvoices] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const response = await fetch(json_url + "/data/invoices", {
          headers: { "x-api-key": sessionStorage.getItem("token") },
        });
        const result = await validateResponse(response).json();
        setInvoices(result);
      } catch (error) {
        console.error("Failed fetching invoice data", error);
      } finally {
        setLoading(false);
      }
    };
    fetchInvoices();
  }, []);

  function download(billingID) {
    const sendData = async () => {
      fetch(json_url + `/data/invoices/download?id=${billingID}`, {
        headers: { "x-api-key": sessionStorage.getItem("token") },
      })
        .then((response) => {
          const filename = response.headers
            .get("Content-Disposition")
            .split("filename=")[1];

          return validateResponse(response)
            .blob()
            .then((blob) => ({ blob: blob, filename: filename }));
        })
        .then((data) => {
          const url = URL.createObjectURL(data.blob);
          const a = document.createElement("a");
          a.download = `${data.filename}.pdf`;
          a.href = url;
          document.body.appendChild(a);
          a.click();
          a.remove();
          console.log("Successfully sent data and received response!");
        })
        .catch((error) => {
          console.error("Error sending data to server:", error);
        });
    };
    sendData();
  }

  function convertToTrow(arr) {
    if (loading) return <tr>Daten werden geladen...</tr>;
    if (!arr) return <tr>Keine Daten verfügbar!</tr>;
    return arr.map((billing) => {
      const buttons = {
        "Download PDF": () => download(billing.billingID),
      };
      return (
        <Trow
          buttons={buttons}
          billingID={billing.billingID}
          date={format(billing.date, "dd.MM.yyyy")}
          name={`${billing.firstName} ${billing.lastName}`}
          address={`${billing.address}, ${billing.zip} ${billing.city}`}
        />
      );
    });
  }

  return (
    <div className={classes.body}>
      <div className={`${classes.Container}`}>
        <h1 className={classes.h1}>Rechnungen</h1>
        <div className={classes["list-container"]}>
          <table className={classes.table}>
            <Thead
              titles={[
                "Rechnungsnummer",
                "Datum",
                "Name",
                "Anschrift",
                "Download",
              ]}
            />
            <tbody>{convertToTrow(invoices)}</tbody>
          </table>
        </div>
        <button
          className={classes.button}
          onClick={() =>
            navigate("/billing", {
              state: { isAuthenticated: true },
            })
          }
        >
          Zurück
        </button>
      </div>
    </div>
  );
}
