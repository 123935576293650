//weekString = 2024-W24
import { format } from "date-fns";
export function getDateRange(dateString, dayCount = 7) {
  const date = new Date(dateString);
  let dates = [];
  dates.push(new Date(dateString));
  for (let i = 0; i < dayCount; i++) {
    dates.push(date.setDate(date.getDate() + 1));
  }
  return dates.map((date) => format(new Date(date), "dd.MM.yyyy"));
}
