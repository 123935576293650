import { useState } from "react";
import { ErrorHandler } from "../components/error-handler";
import classes from "../css/SuccessFail.module.css";
import { ArrowLeft } from "lucide-react";
import { useNavigate, useLocation } from "react-router-dom";

export default function Verify() {
  const [errorMsg, setErrorMsg] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const fetch_url = process.env.REACT_APP_FETCHING_SERVER;

  function navigateBack() {
    if (location.state) {
      navigate(location.state.prevLocation);
    } else {
      navigate("/");
    }
  }

  function submit() {
    if (email == "") {
      setErrorMsg("Du musst eine E-Mail Adresse angeben!");
      return;
    }

    fetch(fetch_url + "/verify", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email }),
    })
      .then((response) => {
        if (response.status == 400) {
          setErrorMsg("Die Email wurde bereits verifiziert!");
          return;
        }

        if (response.status == 404) {
          setErrorMsg(
            <>
              Die Email ist noch nicht eingetragen! Wende dich an das{" "}
              <a href="/formula">Antragsformular</a>.
            </>
          );
          return;
        }

        if (response.status == 429) {
          setErrorMsg(
            "Du kannst jede Minute nur einen Verifizierungslink verschicken. Bitte warte einen Moment!"
          );
          return;
        }

        return response.json();
      })
      .then((data) => {
        if (data) {
          setErrorMsg(
            "Die Verifizierungs-Email wurde abgeschickt! Schaue in deinem Postfach nach (ggf. im Spam-Ordner!)"
          );
        }
      })
      .catch((error) => {
        console.error("Failed fetching email data: ", error);
        setErrorMsg("Ups! Ein Fehler ist aufgetreten. Versuche es nochmal!");
      });
  }

  return (
    <div className={classes.body}>
      <ErrorHandler
        errorMsg={errorMsg}
        toggleAction={() => setErrorMsg("")}
      ></ErrorHandler>
      <div className={classes.title}>
        <h4>Verifiziere deine Email-Adresse</h4>
        <h5>
          Hier kannst du deine Email-Adresse bestätigen, wenn du das nicht schon
          zuvor gemacht hast!
        </h5>
      </div>

      <div className={classes["message-container"]}>
        <input
          type="text"
          value={email}
          placeholder={"Email-Adresse"}
          maxLength={100}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button onClick={submit}>Verifizieren</button>
        <div className={classes.backRef}>
          <ArrowLeft size={16} />
          <a onClick={navigateBack}>Zurück</a>
        </div>
      </div>
    </div>
  );
}
