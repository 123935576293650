import table from "../css/Table.module.css";

export function Thead({ titles }) {
  function convertToThead(list) {
    return list.map((title) => {
      return <th className={table.th}>{title}</th>;
    });
  }

  return (
    <thead>
      <tr>{convertToThead(titles)}</tr>
    </thead>
  );
}
