import { useEffect, useState } from "react";
import classes from "../css/Booking.module.css";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Timeslot } from "../components/timeslot";
import { format } from "date-fns";
import { ErrorHandler } from "./error-handler";
import { DateField } from "./date-field";
import validateResponse from "../../utils/requestValidator";

const json_url = process.env.REACT_APP_FETCHING_SERVER;

function getPreviousDateWithOffset(dateFormatted, offset = 0) {
  const previousDate = new Date(dateFormatted);

  previousDate.setDate(previousDate.getDate() - 1 + offset);

  return previousDate;
}

let mindate = new Date();
mindate.setDate(mindate.getDate() + 2);
mindate = format(mindate, "yyyy-MM-dd");
console.log(mindate);

function getDates(offset = 0) {
  let dates = [];

  const d = getPreviousDateWithOffset(mindate, offset);
  for (var i = 1; i <= 7; i++) {
    dates.push(d.setDate(d.getDate() + 1));
  }
  return dates;
}

const month = [
  "Jan",
  "Feb",
  "Mär",
  "Apr",
  "Mai",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Okt",
  "Nov",
  "Dez",
];

export function TimeslotForm({
  transIdx,
  nextStep,
  backStep,
  giveTimeslotIDs = () => {},
  getTimeslotIDs = () => {},
  setErrorMsg = () => {},
  getErrorMsg = () => {},
}) {
  const [timeslots, setTimeslots] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    format(new Date(mindate), "yyyy-MM-dd")
  );
  const [dateOffset, setOffset] = useState(0);

  // const dates = getDates(dateOffset);

  const addTimeslot = (timeslotID) =>
    giveTimeslotIDs((timeslotIDs) => [...timeslotIDs, timeslotID]);

  const removeTimeslot = (timeslotID) =>
    giveTimeslotIDs((timeslotIDs) =>
      timeslotIDs.filter(
        (selectedTimeslotID) => selectedTimeslotID != timeslotID
      )
    );

  useEffect(() => {
    if (transIdx < 1) {
      sessionStorage.removeItem("token");
      setTimeout(returnToInitState, 1000);
    }

    if (transIdx == 1) {
      loadTimeslots(format(selectedDate, "yyyy-MM-dd"));
    }
  }, [transIdx]);

  useEffect(() => {
    if (transIdx === 1) {
      document.addEventListener("keyup", checkTimeslotConstraint);
    }

    return () => {
      document.removeEventListener("keyup", checkTimeslotConstraint);
    };
  });

  const returnToInitState = () => {
    setTimeslots([]);
    setSelectedDate(format(new Date(mindate), "yyyy-MM-dd"));
    giveTimeslotIDs([]);
    setOffset(0);
  };

  function loadTimeslots(date) {
    setSelectedDate(date);
    fetch(json_url + `/data/timeslots?date=${date}`, {
      headers: { "x-api-key": sessionStorage.getItem("token") },
    })
      .then((response) => validateResponse(response).json())
      .then((result) => {
        console.log("test!");
        if (result.includes("429")) {
          setErrorMsg("Zu viele Anfragen!");
          return;
        }

        setTimeslots(result);
      })
      .catch((error) => {
        setErrorMsg(
          "Ups! Ein Fehler ist aufgetreten! Der Server reagiert nicht!"
        );
        console.error(`Error fetching timeslots from ${date}`, error);
      });
  }

  function convertToTimeslots(timeslots) {
    if (!timeslots.length) {
      return (
        <p className={classes.errorMsg}>
          Für das eingegebene Datum gibt es keine Termine!
        </p>
      );
    }

    return timeslots.map((timeslot) => {
      return (
        <Timeslot
          add={() => addTimeslot(timeslot.timeslotID)}
          remove={() => removeTimeslot(timeslot.timeslotID)}
          get={() => getTimeslotIDs()}
          key={timeslot.timeslotID}
          timeslotID={timeslot.timeslotID}
          begin={timeslot.begin}
          end={timeslot.end}
          claimed={timeslot.claimed}
          quantity={timeslot.quantity}
        />
      );
    });
  }

  function checkTimeslotConstraint() {
    if (getTimeslotIDs().length < 1) {
      setErrorMsg("Du musst mindestens ein Zeitfenster auswählen!");
      return;
    } else if (getTimeslotIDs().length > 3) {
      setErrorMsg("Du kannst nur maximal 3 Zeitfenster gleichzeitig wählen!");
      return;
    }

    nextStep();
  }

  function loadOffset(shiftOffset) {
    if (shiftOffset < 0) {
      setOffset((offset) =>
        offset >= -shiftOffset ? offset + shiftOffset : 0
      );
    } else {
      setOffset((offset) => offset + shiftOffset);
    }
    setTimeout(1000);
    loadTimeslots(
      format(
        getDates(
          dateOffset + (dateOffset >= -shiftOffset ? shiftOffset : 0)
        )[0],
        "yyyy-MM-dd"
      )
    );
  }

  return (
    <>
      <ErrorHandler
        errorMsg={getErrorMsg()}
        toggleAction={() => setErrorMsg("")}
      />
      <div className={`${classes.step2} ${classes[`transition${transIdx}`]}`}>
        <div className={classes.backButton} onClick={backStep}>
          <ChevronLeft className={classes.backIcon} size={40}></ChevronLeft>
        </div>
        <div className={`${classes.InputContainer}`}>
          <div className={classes.monthSelection}>
            <h3>
              {month[new Date(selectedDate).getMonth()]}{" "}
              {new Date(selectedDate).getFullYear()}
            </h3>
            <div className={classes["back-and-forth"]}>
              <ChevronLeft
                size={32}
                className={classes.dateArrow}
                onClick={() => loadOffset(-7)}
              ></ChevronLeft>
              <ChevronRight
                className={classes.dateArrow}
                size={32}
                onClick={() => loadOffset(7)}
              ></ChevronRight>
            </div>
          </div>
          <div className={classes.dateFields}>
            {getDates(dateOffset).map((date, idx) => {
              return (
                <DateField
                  key={idx}
                  uniqueID={idx}
                  date={new Date(date)}
                  giveDate={loadTimeslots}
                  selectedDate={selectedDate}
                />
              );
            })}
          </div>
          <div className={classes.timeslots}>
            {convertToTimeslots(timeslots)}
          </div>
          <button tabIndex={-1} onClick={checkTimeslotConstraint}>
            Bestätigen
          </button>
        </div>
      </div>
    </>
  );
}
