import classes from "../css/Booking.module.css";
import { useEffect, useState } from "react";
import { ErrorHandler } from "./error-handler";
import { ArrowLeft, CircleHelp } from "lucide-react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";

const CustomToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#4741ea",
    maxWidth: "500px",
    width: "30vw",
    minWidth: "150px",
    fontSize: "clamp(0.6em,2vw,0.8em)",
    fontFamily: "Poppins",
  },
});

export function EmailForm({
  transIdx,
  nextStep,
  backStep,
  giveStudent,
  getErrorMsg = () => {},
  setErrorMsg = () => {},
}) {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [studentID, setStudentID] = useState(null);
  const [password, setPassword] = useState("");
  const json_url = process.env.REACT_APP_FETCHING_SERVER;

  const location = useLocation();
  const navigate = useNavigate();

  function checkPassword() {
    if (password === "") {
      setErrorMsg("Du musst eine PIN eingeben!");
      return;
    }

    if (transIdx >= 1) {
      return;
    }

    fetch(json_url + "/booking-password", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ studentID, password }),
    })
      .then((response) => {
        if (response.status == 404) {
          setErrorMsg("Die PIN ist nicht korrekt!");
          return;
        }

        if (response.status == 429) {
          setErrorMsg("Zu viele Anfragen!");
          return;
        }
        return response.json();
      })
      .then((student) => {
        if (student) {
          giveStudent(student);
          nextStep();
        }
      })
      .catch((error) => {
        console.error("Failed fetching student with password: ", error);
        setErrorMsg(
          "Ups! Ein Fehler ist aufgetreten! Der Server reagiert nicht mehr!"
        );
      });
  }

  function checkEmail() {
    if (studentID) {
      checkPassword();
      return;
    }

    if (email === "" || !email.includes("@")) {
      setErrorMsg("Du musst eine E-Mail Adresse angeben!");
      return;
    }

    fetch(json_url + `/booking-email`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email }),
    })
      .then((response) => {
        if (response.status === 404) {
          setErrorMsg(
            <>
              Diese Email ist noch nicht eingetragen! Wende dich an das{" "}
              <a href="/formula">Antragsformular</a>.
            </>
          );
          return;
        } else if (response.status === 400) {
          setErrorMsg(
            <>
              Diese Email wurde noch nicht verifiziert <br />
              Klicke <a href="/verify">hier</a>, um die Verifizierung
              nachzuholen
            </>
          );
          return;
        } else if (response.status === 429) {
          setErrorMsg("Zu viele Anfragen!");
          return;
        } else {
          return response.json();
        }
      })
      .then((student) => {
        if (student) {
          setStudentID(student.studentID);
        }
      })
      .catch((error) => {
        console.error("Failed fetching email for id: ", error);
        setErrorMsg(
          "Ups! Ein Fehler ist aufgetreten! Der Server reagiert nicht mehr!"
        );
      });
  }

  useEffect(() => {
    if (transIdx < 1) {
      setEmail("");
      setPassword("");
      setStudentID(null);
    }
  }, [transIdx]);

  return (
    <>
      <ErrorHandler
        errorMsg={getErrorMsg()}
        toggleAction={() => setMessage("")}
      />
      <div
        className={`${classes.InputContainer} ${classes.step1} ${
          classes[`transition${transIdx}`]
        }`}
      >
        <div className={classes.backRef}>
          <ArrowLeft size={16} />
          <a href="/">Zurück zur Homepage</a>
        </div>

        <input
          type="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setMessage("");
            setStudentID(null);
          }}
          onKeyUp={(e) => (e.key == "Enter" ? checkEmail() : "")}
          onClick={() => setMessage("")}
          placeholder="Email-Adresse"
          maxLength={100}
        />
        {/*  Du hast mit deiner Verifizierungs-Email eine PIN erhalten!
            <br /> Gebe diese PIN ein. */}

        {studentID && (
          <div className={classes.passwordInput}>
            <CustomToolTip
              id={"passwordTip"}
              title={
                <p className={classes["tooltip-text"]}>
                  Du hast mit deiner Verifizierungs-Email eine PIN erhalten!
                  Gebe diese PIN ein. Falls du deine PIN vergessen hast, kannst
                  du sie <a href={"/restore"}>hier</a> neu beantragen.
                </p>
              }
              placement="left-end"
            >
              <CircleHelp
                data-tip
                data-tooltip-id={"passwordTip"}
                className={classes.tooltip}
              />
            </CustomToolTip>
            <input
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setMessage("");
              }}
              onKeyUp={(e) => (e.key == "Enter" ? checkEmail() : "")}
              onClick={() => setMessage("")}
              placeholder="PIN"
              maxLength={100}
            />
          </div>
        )}
        {message}
        <button type="submit" onClick={checkEmail}>
          Bestätigen
        </button>
      </div>
    </>
  );
}
