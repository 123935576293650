import { useEffect, useState } from "react";
import { X } from "lucide-react";
import classes from "../css/ErrorHandler.module.css";

export function ErrorHandler({ errorMsg, toggleAction, delay = 7000 }) {
  const [opened, toggleOpen] = useState(false);
  const [message, setMessage] = useState(errorMsg);

  useEffect(() => {
    toggleOpen(errorMsg ? true : false);
    setMessage(errorMsg);
  }, [errorMsg]);

  function close() {
    toggleAction();
    toggleOpen(false);
    setMessage("");
  }

  return (
    <div
      className={`${classes.errorContainer} ${opened ? classes.opened : ""}`}
    >
      <div className={classes.close} onClick={close}>
        <X size={24}></X>
      </div>
      <p className={classes.errorMsg}>{message}</p>
    </div>
  );
}
