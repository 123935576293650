import { Header } from "../components/static/header";
import { Sidebar } from "../components/static/sidebar";
import { Footer } from "../components/static/footer";
import { StudentForm } from "../components/student-form";
import { useState } from "react";
import classes from "../css/Formula.module.css";

export default function Formula() {
  const [sidebarToggled, toggleSidebar] = useState(false);

  return (
    <div className={classes.html}>
      <div className={classes.background}></div>
      <div className={classes.body}>
        <Sidebar isToggled={sidebarToggled} />
        <Header handleClick={() => toggleSidebar(!sidebarToggled)} />
        <section className={classes.section}>
          <StudentForm />
        </section>
        <Footer />
      </div>
    </div>
  );
}
