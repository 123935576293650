import classes from "../../css/Homepage.module.css";

export function BlueBlobs() {
  return (
    <>
      <svg
        className={classes.blob}
        width="150"
        height="114"
        viewBox="0 0 150 114"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M148.161 32.0947C144.595 84.6429 115.744 111.89 61.6068 113.836C7.46964 115.783 -35.9697 100.375 -68.7113 67.6134C-101.453 34.8519 -120.255 -4.39702 -125.118 -50.1333C-129.98 -95.8697 -114.744 -139.011 -79.4091 -179.557C-44.0741 -220.104 0.17572 -232.268 53.3403 -216.049C106.505 -199.831 136.491 -167.556 143.299 -119.224C150.106 -70.8931 151.727 -20.4534 148.161 32.0947Z"
          fill="#BFCBD4"
        />
      </svg>

      <svg
        className={classes.blob}
        width="313"
        height="81"
        viewBox="0 0 313 81"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M212.649 -46.7272C348.148 -20.7498 345.18 13.516 203.746 56.07C62.3123 98.6239 -5.43731 85.6352 0.497622 17.1037C6.43254 -51.4278 77.1496 -72.7047 212.649 -46.7272Z"
          fill="#BFCBD4"
        />
      </svg>
      <svg
        className={classes.blob}
        width="153"
        height="170"
        viewBox="0 0 153 170"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M291.433 75.0465C293.121 129.145 266.366 159.939 211.17 167.431C155.973 174.922 109.96 163.58 73.1315 133.405C36.3026 103.23 13.0781 65.005 3.45788 18.7292C-6.16229 -27.5466 5.04657 -73.2112 37.0845 -118.264C69.1223 -163.318 113.164 -180.253 169.21 -169.07C225.257 -157.887 259.217 -127.93 271.091 -79.1982C282.964 -30.4664 289.745 20.9485 291.433 75.0465Z"
          fill="#BFCBD4"
        />
      </svg>
      <svg
        className={classes.blob}
        width="106"
        height="191"
        viewBox="0 0 106 191"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-41.6883 0.918509C13.3529 1.05603 47.8862 12.9507 61.9115 36.6023C75.9368 60.254 79.7073 76.955 73.2232 86.7052C66.739 96.4554 74.0131 117.167 95.0454 148.84C116.078 180.514 103.826 220.553 58.2908 268.958C12.7554 317.364 -13.7351 307.787 -21.1808 240.227C-28.6265 172.667 -47.6662 115.858 -78.2998 69.7996C-108.933 23.7413 -96.7295 0.780985 -41.6883 0.918509Z"
          fill="#BFCBD4"
        />
      </svg>
      <svg
        className={classes.blob}
        width="426"
        height="61"
        viewBox="0 0 426 61"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M422.959 70.3673C415.163 88.736 393.272 100.258 357.287 104.934C321.302 109.609 241.836 118.126 118.888 130.483C-4.05961 142.84 -31.6479 122.635 36.1234 69.8663C103.895 17.0981 168.067 -5.77928 228.642 1.23422C289.216 8.24772 327.6 12.7564 343.793 14.7603C359.986 16.7641 379.178 21.9407 401.368 30.2901C423.559 38.6395 430.756 51.9986 422.959 70.3673Z"
          fill="#BFCBD4"
        />
      </svg>

      <svg
        className={classes.blob}
        width="176"
        height="215"
        viewBox="0 0 176 215"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M247.74 181.352C251.74 217.352 232.573 247.352 190.24 271.352C147.906 295.352 99.073 294.019 43.7397 267.352C-11.5937 240.685 -14.427 206.019 35.2397 163.352C84.9063 120.685 114.74 83.6854 124.74 52.3521C134.74 21.0188 162.073 3.68545 206.74 0.352113C251.406 -2.98122 268.406 17.3521 257.74 61.3521C247.073 105.352 243.74 145.352 247.74 181.352Z"
          fill="#BFCBD4"
        />
      </svg>
    </>
  );
}
