import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Trow } from "../components/table-element";
import { Thead } from "../components/table-head";
import classes from "../css/Table.module.css";
import validateResponse from "../../utils/requestValidator";
const json_url = process.env.REACT_APP_FETCHING_SERVER;

export default function Appointments() {
  const [appointments, setAppointments] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingCount, setLoadingCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    fetch(json_url + "/data/appointments?req=all", {
      headers: { "x-api-key": sessionStorage.getItem("token") },
    })
      .then((response) => validateResponse(response).json())
      .then((data) => {
        setAppointments(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Failed fetching all appointments:", error);
        setLoading(false);
      });
  }, [loadingCount]);

  function claim(appointmentID, claimed) {
    fetch(
      json_url +
        `/data/appointments/claim?id=${appointmentID}&claimed=${claimed}`,
      { headers: { "x-api-key": sessionStorage.getItem("token") } }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Successfully fetched appointment claiming:", data);
        setLoadingCount(loadingCount + 1);
      })
      .catch((error) => {
        console.error("Error fetching appointmentID:", error);
      });
  }

  function convertToTrow(arr) {
    if (loading) return <tr>Daten werden geladen...</tr>;
    if (!arr) return <tr>Keine Daten verfügbar!</tr>;
    return arr.map((appointment) => {
      const buttons = {
        Erschienen: () => claim(appointment.AppointmentID, true),
        Verpasst: () => claim(appointment.AppointmentID, null),
      };
      return (
        <Trow
          buttons={buttons}
          date={appointment.DateTime}
          name={`${appointment.FirstName} ${appointment.LastName}`}
          quantity={appointment.Quantity}
          service={appointment.ServiceName}
          totalprice={appointment.TotalPrice}
        />
      );
    });
  }

  return (
    <div className={classes.body}>
      <div className={`${classes.Container}`}>
        <h1 className={classes.h1}>Termine bestätigen</h1>
        <div className={classes["list-container"]}>
          <table className={classes["table"]}>
            <Thead
              titles={[
                "Beginn",
                "Name",
                "Anzahl",
                "Leistung",
                "Gesamtpreis",
                "Erschienen?",
                "Verpasst?",
              ]}
            />
            <tbody>{convertToTrow(appointments)}</tbody>
          </table>
        </div>
        <button
          className={classes.button}
          onClick={() =>
            navigate("/billing", {
              state: { isAuthenticated: true },
            })
          }
        >
          Zurück
        </button>
      </div>
    </div>
  );
}
