import classes from "../../css/Homepage.module.css";

export function TransBlobs() {
  return (
    <>
      {/* <svg
        className={`${classes.blob} ${classes.blob1}`}
        width="382"
        height="122"
        viewBox="0 0 382 122"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.8"
          d="M369.587 40.9877C350.92 100.654 311.754 127.154 252.087 120.488C192.42 113.821 131.42 101.988 69.0869 84.9877C6.75354 67.9877 -16.2465 27.4877 0.0868645 -36.5123C16.4202 -100.512 46.7535 -148.346 91.0869 -180.012C135.42 -211.679 184.587 -223.012 238.587 -214.012C292.587 -205.012 332.587 -175.179 358.587 -124.512C384.587 -73.8456 388.254 -18.679 369.587 40.9877Z"
          fill="#403E94"
        />
      </svg>

      <svg
        className={classes.blob}
        width="529"
        height="124"
        viewBox="0 0 529 124"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.8"
          d="M342.818 -180.491C391.419 -142.497 440.478 -104.997 489.993 -67.9903C539.509 -30.984 541.248 7.04303 495.209 46.0907C449.171 85.1383 399.301 107.633 345.6 113.574C291.898 119.515 232.599 122.88 167.703 123.669C102.806 124.458 53.3373 100.62 19.2968 52.1551C-14.7436 3.69032 -2.27533 -35.3113 56.7018 -64.8497C115.679 -94.3882 165.959 -130.545 207.542 -173.32C249.125 -216.095 294.217 -218.485 342.818 -180.491Z"
          fill="#403E94"
        />
      </svg>

      <svg
        className={classes.blob}
        width="216"
        height="147"
        viewBox="0 0 216 147"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.8"
          d="M374.587 65.9877C355.92 125.654 316.754 152.154 257.087 145.488C197.42 138.821 136.42 126.988 74.0869 109.988C11.7535 92.9877 -11.2465 52.4877 5.08686 -11.5123C21.4202 -75.5123 51.7535 -123.346 96.0869 -155.012C140.42 -186.679 189.587 -198.012 243.587 -189.012C297.587 -180.012 337.587 -150.179 363.587 -99.5123C389.587 -48.8456 393.254 6.32103 374.587 65.9877Z"
          fill="#403E94"
        />
      </svg> */}

      <svg
        className={classes.blob}
        width="149"
        height="271"
        viewBox="0 0 149 271"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.8"
          d="M-211.704 207.63C-219.233 158.524 -206.711 112.546 -174.139 69.6952C-141.567 26.8447 -97.3522 3.75546 -41.4949 0.427423C14.3624 -2.90062 49.842 20.6083 64.9439 70.9542C80.0458 121.3 101.648 168.235 129.751 211.759C157.853 255.283 154.029 299.399 118.277 344.106C82.5256 388.813 36.6761 408.803 -19.2712 404.076C-75.2185 399.349 -119.395 377.703 -151.802 339.138C-184.208 300.572 -204.175 256.737 -211.704 207.63Z"
          fill="#403E94"
        />
      </svg>

      <svg
        className={classes.blob}
        width="331"
        height="77"
        viewBox="0 0 331 77"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.8"
          d="M9.0264 83.415C23.4912 23.4527 56.3181 -3.84234 107.507 1.52984C158.696 6.90203 211.155 17.4031 264.884 33.033C318.613 48.6629 339.297 88.5967 326.936 152.834C314.575 217.072 289.837 265.473 252.722 298.037C215.606 330.6 173.848 342.97 127.446 335.147C81.0435 327.324 46.0903 298.403 22.5859 248.385C-0.918548 198.367 -5.43838 143.377 9.0264 83.415Z"
          fill="#403E94"
        />
      </svg>

      <svg
        className={classes.blob}
        width="153"
        height="263"
        viewBox="0 0 153 263"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.8"
          d="M324.504 272.401C318.504 313.068 295.17 348.901 254.504 379.901C213.837 410.901 166.004 418.068 111.004 401.401C56.0038 384.734 21.3371 348.901 7.00379 293.901C-7.32955 238.901 0.337122 191.568 30.0038 151.901C59.6705 112.234 94.3371 72.0675 134.004 31.4008C173.67 -9.26582 214.504 -10.4325 256.504 27.9008C298.504 66.2342 321.837 106.401 326.504 148.401C331.17 190.401 330.504 231.734 324.504 272.401Z"
          fill="#403E94"
        />
      </svg>
    </>
  );
}
