import { useParams } from "react-router-dom";
import classes from "../css/Booking.module.css";
import { useEffect, useState } from "react";

const fetch_url = process.env.REACT_APP_FETCHING_SERVER;

export default function VerifyCheck({ path = "verify" }) {
  const { token } = useParams();

  const [message, setMessage] = useState(["Verifiziere..."]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(fetch_url + `/${path}/${token}`)
      .then((result) => {
        return result.json();
      })
      .then((data) => {
        setMessage(data.message);
      })
      .catch((error) => {
        console.error("Failed verifying token: ", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className={classes.Booking}>
      <div className={classes.container}>
        <div className={classes.InputContainer}>
          <h5 className={classes.message}>
            {message.map((line) => {
              return (
                <>
                  <p>{line}</p>
                </>
              );
            })}
          </h5>
          {!loading && (
            <a className={classes.a} href="/">
              Zurück zur Homepage
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
