import { useEffect, useState } from "react";
import classes from "../../css/Formula.module.css";

export function Sidebar({ isToggled = false }) {
  const [opened, setOpened] = useState(isToggled);

  useEffect(() => {
    setOpened(isToggled);
  }, [isToggled]);

  let className = `sidebar ${opened ? "open" : ""}`;
  className = `${classes.sidebar} ${opened ? classes.open : ""}`;

  return (
    <div className={className} id="sidebar">
      <a tabIndex={-20} href="/">
        Homepage
      </a>
      <a tabIndex={-20} href="">
        Antragsformular
      </a>
      <a tabIndex={-20} href="/booking">
        Termin buchen
      </a>
    </div>
  );
}
