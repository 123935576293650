import { useState } from "react";
import classes from "../../css/Legal.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeft } from "lucide-react";

const initEmail = { address: "", subject: "", message: "" };

export default function Contact() {
  const [email, setEmail] = useState(initEmail);
  const [errorMsg, setErrorMsg] = useState("");

  const location = useLocation();
  const navigate = useNavigate();

  const fetch_url = process.env.REACT_APP_FETCHING_SERVER;

  function updateValue(key, value) {
    if (email[key] == null) {
      console.log(`The email object does not contain ${key} as attribute!`);
      return;
    }

    setEmail((prevState) => ({ ...prevState, [key]: value }));
  }

  function submitEmail() {
    if (Object.values(email).includes("")) {
      setErrorMsg("Fülle alle Felder aus!");
      return;
    }

    if (!email["address"].includes("@")) {
      setErrorMsg("Gebe eine valide Email-Adresse an!");
      return;
    }

    fetch(fetch_url + "/contact", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(email),
    })
      .then((request) => request.json())
      .then((result) => {
        if (!result.message) {
          setErrorMsg("Es ist ein Fehler aufgetreten!");
        } else {
          setErrorMsg("Nachricht wurde erfolgreich abgeschickt!");
          setEmail(initEmail);
        }
      });
  }

  function navigateBack() {
    if (location.state) {
      navigate(location.state.prevLocation);
    } else {
      navigate("/");
    }
  }

  return (
    <div className={`${classes.LegalContainer} ${classes.Contact}`}>
      <h3>Kontakt</h3>
      <div>
        <h5>
          Sind Fragen aufgekommen? Möchtest du einen Termin manuell buchen oder
          absagen? Gibt es noch Komplikationen?
        </h5>
        <h5>
          Kein Problem! Hierfür ist das Kontaktformular da! Gebe einfach deine
          Email ein und schildere dein Anliegen. Ich behandle deine Anfrage
          schnellstmöglich!
        </h5>
      </div>

      <p>
        Alternativ erreichst du mich unter der Telefonnummer +49 179 1392716
        oder per Email direkt unter osedighimornani@gmail.com
      </p>

      <div className={classes.contactForm}>
        <div className={classes.backRef}>
          <ArrowLeft size={16} />
          <a onClick={navigateBack}>Zurück</a>
        </div>

        <input
          type="email"
          placeholder={"Deine Email-Adresse"}
          value={email.address}
          onChange={(e) => updateValue("address", e.target.value)}
          onClick={() => setErrorMsg("")}
        />
        <input
          type="text"
          placeholder={"Dein Anliegen"}
          value={email.subject}
          onChange={(e) => updateValue("subject", e.target.value)}
          onClick={() => setErrorMsg("")}
        />
        <textarea
          placeholder={"Deine Nachricht!"}
          style={{}}
          value={email.message}
          onChange={(e) => updateValue("message", e.target.value)}
          onClick={() => setErrorMsg("")}
        ></textarea>
        <p className={classes.errorMsg}>{errorMsg}</p>
        <button onClick={submitEmail}>Absenden</button>
      </div>
    </div>
  );
}
