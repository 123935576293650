import { Navigate, useLocation } from "react-router-dom";

export default function ProtectedRoute({ checkForSubmission, children }) {
  const location = useLocation();

  if (checkForSubmission) {
    let fromSubmission = false;

    if (location.state) {
      fromSubmission = location.state.fromSubmission == true;
    }

    if (!fromSubmission) {
      return <Navigate to="/" />;
    }
  } else {
    let isAuthenticated = false;
    if (location.state) {
      isAuthenticated = location.state.isAuthenticated == true;
    }

    if (!isAuthenticated) {
      return <Navigate to="/authenticate" />;
    }
  }

  return children;
}
