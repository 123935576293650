import { useState } from "react";
import classes from "../css/Legal.module.css";
import { Plus } from "lucide-react";

export function InformationContainer({ title, children }) {
  const [active, setActive] = useState(false);

  return (
    <div>
      <div className={classes.title} onClick={() => setActive(!active)}>
        <h4>{title}</h4>
        <div className={`${classes.Plus} ${active ? classes.active : ""}`}>
          <Plus size={32} />
        </div>
      </div>
      <section className={active ? classes.active : ""}>{children}</section>
    </div>
  );
}
