import classes from "../css/Booking.module.css";
import homepage from "../css/Homepage.module.css";
import { TimeslotForm } from "../components/timeslot-form";
import { EmailForm } from "../components/email-form";
import { useState, useEffect } from "react";
import { ChevronLeft } from "lucide-react";
import { ErrorHandler } from "../components/error-handler";
import { Footer } from "../components/static/footer.js";
import { ThreeDots } from "react-loader-spinner";
import { PurpleBlobs } from "../components/static/purple-blobs.js";
import validateResponse from "../../utils/requestValidator";
import { useSessionStorage } from "primereact/hooks";
const fetch_url = process.env.REACT_APP_FETCHING_SERVER;

export default function Booking() {
  const [transitionIdx, setTransition] = useState(0);
  const [student, setStudent] = useState(null);
  const [timeslotIDs, setTimeslotIDs] = useState([]);
  const [timeslots, setTimeslots] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useSessionStorage("", "token");

  const resetInitStates = () => {
    setTransition(0);
    setStudent(null);
    setTimeslotIDs([]);
    setTimeslots([]);
    setErrorMsg("");
    sessionStorage.removeItem("token");
  };

  const transitionNext = () => {
    if (transitionIdx < 2) setTransition(transitionIdx + 1);
  };

  const transitionBack = () => {
    if (transitionIdx > 0) setTransition(transitionIdx - 1);
  };

  const giveStudent = (student) => {
    setStudent(student);
    setToken(student["x-api-key"]);
  };

  const message = [
    {
      title: "Gebe zunächst deine Email-Adresse ein",
      description: (
        <>
          Diese wird benötigt, um im System zu überprüfen, ob du eingetragen
          bist. <br />
          Wenn du ein Neukunde bist, dann klicke <a href="/formula">hier</a>, um
          zum Antragsformular zu gelangen!
        </>
      ),
    },
    {
      title: "Termin auswählen",
      description: (
        <>
          Wähle bis zu drei verfügabare Termine aus! <br />
          Beachte: Du kannst immer nur 2 Tage im Voraus buchen!
        </>
      ),
    },
    {
      title: "Bestätigen",
      description: (
        <>
          Überprüfe nocheinmal alle Daten. <br /> Wenn alles passt dann gebe
          grünes Licht!
        </>
      ),
    },
  ];

  useEffect(() => {
    if (timeslotIDs.length < 1) {
      setTimeslots([]);
      return;
    }
    console.log(fetch_url + `/data/timeslots/${timeslotIDs.join(",")}`);
    fetch(fetch_url + `/data/timeslots/${timeslotIDs.join(",")}`)
      .then((response) => validateResponse(response).json())
      .then((data) => {
        setTimeslots(data);
      })
      .catch((error) => {
        console.error("Error fetching timeslots data from id: ", error);
        setErrorMsg(
          "Ups! Ein Fehler ist aufgetreten! Der Server reagiert nicht!"
        );
      });
  }, [timeslotIDs]);

  useEffect(() => {
    setErrorMsg("");
  }, [transitionIdx]);

  function getTimeslots(timeslotIDs) {
    if (timeslotIDs < 1) return;

    return timeslots.map((timeslot) => {
      return (
        <p key={timeslot.timeslotID}>
          {timeslot.date}: {timeslot.begin} Uhr - {timeslot.end} Uhr
        </p>
      );
    });
  }

  function submit() {
    if (!student || !timeslotIDs) {
      return;
    }
    setLoading(true);
    fetch(fetch_url + "/data/timeslots/claim", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": sessionStorage.getItem("token"),
      },
      body: JSON.stringify({
        studentID: student.studentID,
        timeslotIDs: timeslotIDs,
        serviceID: 1,
      }),
    })
      .then((response) => {
        return validateResponse(response).json();
      })
      .then((data) => {
        resetInitStates();
        setTimeout(
          () =>
            setErrorMsg(
              "Deine Buchung wurde erfolgreich ausgeführt! Schaue in dein Postfach (ggf. Spam!)"
            ),
          1000
        );
      })
      .catch((error) => {
        console.error("Failed fetching timeslot data to server: ", error);
        setErrorMsg("Deine Buchung ist nicht gültig! Probiere es erneut!");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <div className={`${classes.Booking} ${homepage.Booking}`}>
        <PurpleBlobs />
        <div className={classes.bookingContainer}>
          <div className={classes["booking-title"]}>
            <h4>{message[transitionIdx].title}</h4>
            <h5>{message[transitionIdx].description}</h5>
          </div>
          <div className={classes.actionContainer}>
            <EmailForm
              transIdx={transitionIdx}
              nextStep={transitionNext}
              backStep={transitionBack}
              giveStudent={giveStudent}
              setErrorMsg={setErrorMsg}
              getErrorMsg={() => errorMsg}
            />
            <TimeslotForm
              transIdx={transitionIdx}
              nextStep={transitionNext}
              backStep={transitionBack}
              giveTimeslotIDs={setTimeslotIDs}
              getTimeslotIDs={() => timeslotIDs}
              setErrorMsg={setErrorMsg}
              getErrorMsg={() => errorMsg}
            />
            <div className={classes.ThreeDots}>
              {loading && <ThreeDots height="40" width="40" color="white" />}
            </div>
            {!loading && (
              <div
                className={`${classes.step3} ${
                  classes[`transition${transitionIdx}`]
                }`}
              >
                <div className={classes.backButton} onClick={transitionBack}>
                  <ChevronLeft
                    className={classes.backIcon}
                    size={40}
                  ></ChevronLeft>
                </div>
                <div className={`${classes.InputContainer}`}>
                  <h3>Fast geschafft!</h3>
                  <div className={classes.customerInformation}>
                    <div>
                      <b>Vollständiger Name:</b>
                      {student && (
                        <p>
                          {student.firstName} {student.lastName}
                        </p>
                      )}
                    </div>
                    <div>
                      <b>Termin(e):</b>
                      {getTimeslots(timeslots)}
                    </div>
                  </div>
                  <button tabIndex={-1} onClick={submit}>
                    Verbindlich buchen
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <ErrorHandler
          errorMsg={errorMsg}
          toggleAction={() => setErrorMsg("")}
        />
      </div>
      <Footer />
    </>
  );
}
