import { useEffect, useState } from "react";
import classes from "../css/Booking.module.css";
import { useSpring, animated } from "react-spring";

export function Timeslot({
  add = () => {},
  remove = () => {},
  get = () => {},
  ...args
}) {
  const uniqueID = args.timeslotID;
  const springs = useSpring({
    from: { opacity: 0.5, transform: "scale(0.9)" },
    to: { opacity: 1, transform: "scale(1)" },
  });

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (get().includes(uniqueID)) setChecked(true);
    else setChecked(false);
  }, []);

  useEffect(() => {
    if (checked) {
      add();
    } else {
      remove();
    }
  }, [checked]);

  return (
    <animated.div
      className={classes.timeslot}
      key={`${uniqueID}`}
      style={{ ...springs }}
    >
      <input
        type="checkbox"
        id={`${uniqueID}`}
        className={classes["time-checkbox"]}
        disabled={args.claimed}
        checked={checked}
        onChange={() => setChecked(!checked)}
      />
      <label
        htmlFor={uniqueID}
        className={`${classes["time-label"]} ${
          args.claimed ? classes.disabled : ""
        }`}
      >
        {args.begin}
      </label>
    </animated.div>
  );
}
