import { useState } from "react";
import classes from "../css/Main.module.css";
import validateResponse from "../../utils/requestValidator";

const json_url = process.env.REACT_APP_FETCHING_SERVER;

export function ServiceForm() {
  const [visibility, setVisibility] = useState(false);
  const [service, setService] = useState(["", 0]);

  function toggleVisibility() {
    setVisibility(!visibility);
    setService(["", 0]);
  }

  function submit_service() {
    if (service[0] == "") {
      alert("Die Leistung muss einen Namen haben!");
      return;
    }

    if (service[1] <= 0) {
      alert("Der Preis muss größer gleich 0 sein!");
      return;
    }

    fetch(json_url + "/submit-service", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": sessionStorage.getItem("token"),
      },
      body: JSON.stringify(service),
    })
      .then((response) => validateResponse(response).json())
      .then((data) => {
        console.log("Leistung wurde erfolgreich abgesendet!", data);
        setVisibility(false);
        setService(["", 0]);
      })
      .catch((error) => {
        console.error("Senden der Leistung fehlgeschlagen!", error);
      });
  }

  let style = { display: visibility ? "block" : "none" };

  return (
    <>
      <button
        className={classes.button}
        type="button"
        onClick={toggleVisibility}
      >
        + Leistung
      </button>
      <div className={classes.overlay} style={style}></div>
      <div className={`${classes.containers}`} style={style}>
        <div
          className={classes["menu-burger"]}
          onClick={toggleVisibility}
        ></div>
        <h2 className={classes.h2}>Neue Leistung erstellen</h2>
        <label className={classes.label}>Name der Leistung:</label>
        <input
          className={classes.input}
          type="text"
          id="serviceName"
          value={service[0]}
          onChange={(e) => setService([e.target.value, service[1]])}
          required
        />

        <label className={classes.label}>Preis:</label>
        <input
          className={classes.input}
          type="number"
          id="servicePrice"
          step="0.01"
          value={service[1]}
          onChange={(e) => setService([service[0], e.target.value])}
          required
        />
        <button className={classes.button} onClick={submit_service}>
          Erstellen
        </button>
      </div>
    </>
  );
}
