import { Option } from "./dropdown-option";
import { useState, useEffect } from "react";
import classes from "../css/Main.module.css";
import validateResponse from "../../utils/requestValidator";

const json_url = process.env.REACT_APP_FETCHING_SERVER;

export function Select({ onDropdownChange, target, value = "" }) {
  const [data, setData] = useState([]);
  const [selectedValue, setSelectedValue] = useState(value);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  function handleChange(event) {
    setSelectedValue(event.target.value);
    onDropdownChange(event.target.value);
  }

  useEffect(() => {
    if (target == "students") {
      target = "students?verified=true";
    }

    fetch(json_url + `/data/${target}`, {
      headers: { "x-api-key": sessionStorage.getItem("token") },
    })
      .then((data) => validateResponse(data).json())
      .then((result) => {
        setData(result);
      })
      .catch((error) => {
        console.error(`Failed fetching ${target}`, error);
      });
  }, []);

  return (
    <select
      className={classes.select}
      id="customerDropdown"
      value={selectedValue}
      onChange={handleChange}
    >
      <option value={""} key={"-1"} selected disabled>
        --Bitte wählen--
      </option>
      {mapToOption(data, target)}
    </select>
  );
}

function mapToOption(arr, target) {
  if (!arr)
    return (
      <option value={""} key={"-2"} disabled>
        Keine Daten verfügbar!
      </option>
    );

  switch (target) {
    case "students":
      return arr.map((student) => {
        return (
          <Option
            ID={student["studentID"]}
            firstName={student["firstName"]}
            lastName={student["lastName"]}
          />
        );
      });

    case "services":
      return arr.map((service) => {
        return (
          <Option
            ID={service["serviceID"]}
            name={service["name"]}
            price={service["price"]}
          />
        );
      });
  }
}
