import { useState } from "react";
import classes from "../css/SuccessFail.module.css";

export default function SuccessPage({ isSuccessfull }) {
  const [isSubmitted, setSubmitted] = useState(true);

  let message = isSuccessfull ? (
    <p className={classes.p}>
      Ihre Daten wurden übermittelt. Vielen Dank! <br /> <br />
      Es wurde eine Verifizierungs-Email gesendet. Klicken Sie auf den Link in
      der Email, um Ihre Email-Adresse zu bestätigen! <br />
      <br />
      Schauen Sie ggf. im Spam-Ordner nach!
    </p>
  ) : (
    <p className={classes.p}>
      Es ist ein Fehler aufgetreten! Probiere es nochmal!
    </p>
  );

  return isSubmitted ? (
    <div className={classes.body}>
      <div className={classes["message-container"]}>
        {message}
        <a className={classes.a} href="/formula">
          Zurück zum Formular
        </a>
      </div>
    </div>
  ) : (
    <p>Auf diese Seite kann nicht zugegriffen werden!</p>
  );
}
