export default function validateResponse(response) {
  if (!response.ok) {
    if (response.status == 400) {
      throw new Error("Bad request! (400)");
    }

    if (response.status == 401) {
      throw new Error("Unauthorized! (401)");
    }

    if (response.status == 408) {
      throw new Error("Timed out! (408)");
    }

    if (response.status == 429) {
      return new Error("Too many requests! (429)");
    }
  }

  return response;
}
