import { useState } from "react";
import classes from "../../css/Formula.module.css";

export function Header({ handleClick }) {
  const [menuActive, setActive] = useState(false);

  function actionClick() {
    setActive(!menuActive);
    handleClick();
  }

  return (
    <header className={classes["main-header"]}>
      <h1 className={classes.h1} href="/">
        <a href="/" className={classes["chewy-font"]}>
          Mathematik
        </a>{" "}
        leicht gemacht!
      </h1>

      <nav className={classes.nav}>
        <a className={classes["nav-link"]} href="/">
          Homepage
        </a>
        <a className={classes["nav-link"]} href="">
          Antragsformular
        </a>
        <a className={classes["nav-link"]} href="/booking">
          Termin buchen
        </a>

        <div
          className={`${classes["menu-burger"]} ${
            menuActive ? classes.active : ""
          }`}
          onClick={actionClick}
        ></div>
      </nav>
    </header>
  );
}
