import classes from "../../css/Legal.module.css";
import { InformationContainer } from "../../components/InformationContainer";
import { ArrowLeft } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";

export default function PrivacyPolicy() {
  const location = useLocation();
  const navigate = useNavigate();

  function navigateBack() {
    if (location.state) {
      navigate(location.state.prevLocation);
    } else {
      navigate("/");
    }
  }

  return (
    <div className={`${classes.PrivacyPolicy} ${classes.LegalContainer}`}>
      <div className={classes.policyTitle}>
        <h3>Datenschutzerklärung</h3>
        <div className={classes.backRef}>
          <ArrowLeft size={16} />
          <a onClick={navigateBack}>Zurück</a>
        </div>
      </div>

      <ul className={classes.Themes}>
        <li>
          <InformationContainer title={"Verantwortlicher"}>
            <p>
              <b>
                Verantwortlicher für die Bearbeitung personenbezogener Daten
                ist:
              </b>
            </p>
            <p>
              <i>Omid Sedighi-Mornani</i>
            </p>
            <p>Am Sonnenhügel 15</p>
            <p>97320 Mainstockheim</p>
            <p>osedighimornani@gmail.com</p>
            <p>+49 179 1392716</p>
          </InformationContainer>
        </li>

        <li>
          <InformationContainer
            title={"Erhebung und Speicherung personenbezogener Daten"}
          >
            <p>
              Wenn Sie sich zur Buchung von Nachhilfeterminen eintragen, werden
              personenbezogene Daten erhoben. Dies geschieht, um eine
              reibungslose Terminvergabe zu gewährleisten und Ihnen den
              bestmöglichen Service zu bieten.
            </p>
            <p>
              <b>Folgende Daten werden erhoben:</b>
            </p>
            <ul>
              <li>Vor- und Nachname</li>
              <li>Anschrift</li>
              <li>Email-Adresse</li>
              <li>Telefonnummer</li>
              <li>Geburtsdatum und Klassenstufe</li>
            </ul>
            <p>
              <b>Die Erhebung der Daten dient zu folgenden Zwecken:</b>
            </p>
            <ul>
              <li>
                Erstellung eines Benutzerkontos zur Buchung von
                Nachhilfeterminen
              </li>
              <li>
                Kommunikation mit Ihnen per Email oder Telefon (z.B. für
                Terminbestätigungen, Erinnerungen oder Änderungen)
              </li>
              <li>
                Eintragung von Terminen in den Google Kalender, bei dem Ihre
                Email-Adresse als Empfänger eingetragen wird
              </li>
            </ul>
          </InformationContainer>
        </li>

        <li>
          <InformationContainer title={"Weitergabe von Daten an Dritte"}>
            <p>
              Ihre personenbezogenen Daten werden ohne Ihre ausdrückliche
              Zustimmung nicht an Dritte weitergegeben. Eine Ausnahme stellt die
              Weitergabe Ihrer E-Mail-Adresse an den Google Kalender dar, um Sie
              über gebuchte Termine zu informieren.
            </p>
            <p>
              Google wird dabei als Auftragsverarbeiter im Sinne der DSGVO
              eingesetzt. Es gelten die Datenschutzbestimmungen von Google, die
              Sie <a href="https://policies.google.com/privacy">hier</a>{" "}
              einsehen können.
            </p>
          </InformationContainer>
        </li>
        <li>
          <InformationContainer title={"Speicherdauer"}>
            <p>
              Ihre personenbezogenen Daten werden nur so lange gespeichert, wie
              dies für die Durchführung der Nachhilfetermine erforderlich ist.
            </p>
            <p>
              Nach Beendigung der Nachhilfe werden die Daten nach Ablauf der
              Aufbewahrungsfristen gelöscht
            </p>
          </InformationContainer>
        </li>
        <li>
          <InformationContainer title={"Ihre Rechte"}>
            <p>
              <b>Sie haben das Recht:</b>
            </p>

            <ul>
              <li>
                <p>
                  gemäß Art. 15 DSGVO Auskunft über Ihre von mir verarbeiteten
                  personenbezogenen Daten zu verlangen,
                </p>
              </li>
              <li>
                <p>
                  gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger
                  oder Vervollständigung Ihrer bei mir gespeicherten
                  personenbezogenen Daten zu verlangen,
                </p>
              </li>
              <li>
                <p>
                  gemäß Art. 17 DSGVO die Löschung Ihrer bei mir gespeicherten
                  personenbezogenen Daten zu verlangen, soweit nicht gesetzliche
                  Aufbewahrungspflichten entgegenstehen,
                </p>
              </li>
              <li>
                <p>
                  gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer
                  personenbezogenen Daten zu verlangen,
                </p>
              </li>
              <li>
                <p>
                  gemäß Art. 20 DSGVO Ihre personenbezogenen Daten in einem
                  strukturierten, gängigen und maschinenlesbaren Format zu
                  erhalten oder die Übermittlung an einen anderen
                  Verantwortlichen zu verlangen,
                </p>
              </li>
              <li>
                <p>
                  gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung
                  jederzeit zu widerrufen, wodurch ich die Datenverarbeitung,
                  die auf dieser Einwilligung beruht, für die Zukunft nicht mehr
                  fortführen darf,
                </p>
              </li>
              <li>
                <p>
                  gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu
                  beschweren.
                </p>
              </li>
            </ul>
          </InformationContainer>
        </li>
        <li>
          <InformationContainer title={"Datensicherheit"}>
            <p>
              Zum Schutz Ihrer Daten werden geeignete technische und
              organisatorische Maßnahmen ergriffen, um die Sicherheit der
              Datenverarbeitung zu gewährleisten und Ihre Daten vor unbefugtem
              Zugriff, Manipulation oder Verlust zu schützen.
            </p>
          </InformationContainer>
        </li>
        <li>
          <InformationContainer title={"Änderungen der Datenschutzerklärung"}>
            <p>
              Diese Datenschutzerklärung ist aktuell gültig und hat den Stand
              September 2024. Ich behalte mir das Recht vor, diese
              Datenschutzerklärung bei Bedarf anzupassen.
            </p>
          </InformationContainer>
        </li>
      </ul>
    </div>
  );
}
