import classes from "../../css/Homepage.module.css";

export function PurpleBlobs() {
  return (
    <>
      <svg
        className={classes.blob}
        width="104"
        height="185"
        viewBox="0 0 104 185"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M74.8356 73.2964C34.1689 129.296 -22.9977 164.963 -96.6644 180.296C-170.331 195.63 -219.164 167.63 -243.164 96.2964C-267.164 24.963 -254.664 -24.7036 -205.664 -52.7036C-156.664 -80.7036 -110.664 -103.87 -67.6644 -122.204C-24.6644 -140.537 20.0023 -126.537 66.3356 -80.2036C112.669 -33.8703 115.502 17.2964 74.8356 73.2964Z"
          fill="#403E94"
        />
      </svg>
      <svg
        className={classes.blob}
        width="426"
        height="70"
        viewBox="0 0 426 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M422.959 6.36727C415.163 24.736 393.272 36.2581 357.287 40.9338C321.302 45.6095 241.836 54.1259 118.888 66.483C-4.05961 78.8401 -31.6479 58.6345 36.1234 5.86631C103.895 -46.9019 168.067 -69.7793 228.642 -62.7658C289.216 -55.7523 327.6 -51.2436 343.793 -49.2397C359.986 -47.2359 379.178 -42.0593 401.368 -33.7099C423.559 -25.3605 430.756 -12.0014 422.959 6.36727Z"
          fill="#403E94"
        />
      </svg>
      <svg
        className={classes.blob}
        width="184"
        height="183"
        viewBox="0 0 184 183"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M329.836 71.2964C289.169 127.296 232.002 162.963 158.336 178.296C84.669 193.63 35.8356 165.63 11.8356 94.2964C-12.1644 22.963 0.335617 -26.7036 49.3356 -54.7036C98.3356 -82.7036 144.336 -105.87 187.336 -124.204C230.336 -142.537 275.002 -128.537 321.336 -82.2036C367.669 -35.8703 370.502 15.2964 329.836 71.2964Z"
          fill="#403E94"
        />
      </svg>
      <svg
        className={classes.blob}
        width="236"
        height="130"
        viewBox="0 0 236 130"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M228.833 232.121C213.833 278.121 181.167 298.288 130.833 292.621C80.5 286.954 38.6667 295.121 5.33333 317.121C-28 339.121 -56.5 334.954 -80.1667 304.621C-103.833 274.288 -126.333 243.121 -147.667 211.121C-169 179.121 -174.333 143.288 -163.667 103.621C-153 63.9542 -129.667 34.9542 -93.6667 16.6209C-57.6667 -1.71245 -22.8333 -4.87912 10.8333 7.12088C44.5 19.1209 76.3333 31.7875 106.333 45.1209C136.333 58.4542 168 81.4542 201.333 114.121C234.667 146.788 243.833 186.121 228.833 232.121Z"
          fill="#403E94"
        />
      </svg>
      <svg
        className={classes.blob}
        width="122"
        height="232"
        viewBox="0 0 122 232"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M308.439 204.057C276.873 218.95 254.054 232.673 239.983 245.226C225.912 257.778 210.794 259.42 194.629 250.151C178.464 240.883 143.816 245.417 90.6861 263.755C37.5558 282.093 8.22127 269.569 2.68262 226.183C-2.85603 182.797 0.709671 140.766 13.3797 100.088C26.0498 59.4098 56.8938 51.3196 105.912 75.817C154.93 100.314 195.738 87.2835 228.336 36.7243C260.935 -13.8349 275.668 -11.6592 272.536 43.2516C269.405 98.1624 282.497 134.968 311.813 153.667C341.129 172.367 340.004 189.163 308.439 204.057Z"
          fill="#403E94"
        />
      </svg>
    </>
  );
}
