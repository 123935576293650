import table from "../css/Table.module.css";

export function Trow({ buttons = { Löschen: () => {} }, ...args }) {
  const values = Object.values(args);
  const table_data = values.map((data) => {
    return <td>{data}</td>;
  });

  const buttonElements = Object.entries(buttons).map(
    ([btnDescription, handleClick], index) => {
      const classNames = `${table.button} ${table["table-button"]} ${
        table[`button${index + 1}`]
      }`;
      return (
        <td>
          <button type="button" className={classNames} onClick={handleClick}>
            {btnDescription}
          </button>
        </td>
      );
    }
  );

  return (
    <tr>
      {table_data}
      {buttonElements}
    </tr>
  );
}
