import classes from "../css/Homepage.module.css";
import { Footer } from "../components/static/footer";
import { BlueBlobs } from "../components/static/blue-blobs";
import {
  Instagram,
  Youtube,
  Redo,
  Award,
  FileBadge,
  SquareCheckBig,
} from "lucide-react";
import { TransBlobs } from "../components/static/trans-blobs";
import { PurpleBlobs } from "../components/static/purple-blobs";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

export default function Homepage() {
  const navigate = useNavigate();
  const [scrolled, setScrolled] = useState(false);
  const [menuIconToggled, setToggled] = useState(false);

  const scroll = () => {
    if (window.scrollY > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", scroll);

    return () => {
      window.removeEventListener("scroll", scroll);
    };
  }, []);

  return (
    <div className={classes.Homepage}>
      <div className={`${classes.overlay} ${scrolled ? classes.dark : ""}`}>
        <nav>
          <div className={classes.logo}>
            <span
              className={classes.chewy}
              onClick={() => window.scrollTo(0, 0)}
            >
              Mathematik
            </span>{" "}
            <span className={classes.schoolbell}>leicht gemacht</span>
          </div>
          <div className={classes.links}>
            <a href="/booking">Termin buchen</a>
            <a href="/formula">Antragsformular</a>
            <a
              onClick={() => document.getElementById("About")?.scrollIntoView()}
            >
              Über mich
            </a>
          </div>
          <div className={classes.icons}>
            <Instagram color="#fff"></Instagram>
            <Youtube color="#fff" size={33} strokeWidth={1.7}></Youtube>
          </div>
        </nav>
      </div>
      <div
        className={`${classes.sidebar} ${
          menuIconToggled ? classes.toggled : ""
        }`}
      >
        <a href="/booking">Termin buchen</a>
        <a href="/formula">Antragsformular</a>
        <a onClick={() => document.getElementById("About")?.scrollIntoView()}>
          Über mich
        </a>
      </div>
      <div
        className={classes["menu-burger"]}
        onClick={() => setToggled(!menuIconToggled)}
      >
        <div
          className={`${classes["menu-icon"]} ${
            menuIconToggled ? classes.toggled : ""
          }`}
        ></div>
      </div>

      <div className={classes.sections}>
        <header>
          <TransBlobs />
          <div className={classes.background}></div>
          <div className={classes.main}>
            <div className={classes.MainTitle}>
              <h1>LERNEN. VERSTEHEN. MEISTERN.</h1>
              <h2>Dein Weg zu besseren Noten</h2>
            </div>
            <h5 className={classes.SubTitle}>
              Du benötigst Unterstützung in Mathematik? Das ist leicht gemacht.
              <br /> Wir bieten qualitative Nachhilfe aus erster Hand
            </h5>
            <button
              onClick={() => navigate("/booking")}
              className={classes.CTA}
            >
              Buchen
              <div className={classes.description}>
                <span className={`${classes.schoolbell} ${classes.label}`}>
                  Termin <br /> ausmachen!
                </span>
                <Redo
                  color="#fff"
                  size={43}
                  strokeWidth={2.5}
                  className={classes.arrow}
                ></Redo>
              </div>
            </button>
          </div>
        </header>
        <section className={classes.About} id="About">
          <div className={classes.AboutTitle}>
            <BlueBlobs />
            <h1>
              <span className={classes.schoolbell}>Wer</span> bin ich ?
            </h1>
            <h5>
              <b>Hi! Ich bin Omid.</b> Aktuell studiere ich an der
              Julius-Maximilians-Universität Würzburg. <br /> Mit meinen
              Kenntnissen und Erfahrungen stehe ich dir zur Seite, um deine
              Herausforderungen zu meistern!
            </h5>
          </div>
          <div className={classes.AboutInformation}>
            <div className={classes.awards}>
              <div className={classes.award}>
                <Award size={40}></Award>
                <h4>1,3-Schnitt</h4>
                <h5>im Abitur</h5>
              </div>
              <div className={classes.award}>
                <FileBadge size={40}></FileBadge>
                <h4>DMV-Abiturpreis</h4>
                <h5>der Deutschen Mathematiker-Vereinigung</h5>
              </div>
              <div className={classes.award}>
                <SquareCheckBig size={40}></SquareCheckBig>
                <h4>Sehr gute Leistungen</h4>
                <h5>im Fach Mathematik</h5>
              </div>
            </div>
            <img
              src="static/img/portrait.png"
              alt="Portrait of Omid!"
              width="396"
              height="387"
            />
          </div>
        </section>
        <section className={classes.Services}>
          <PurpleBlobs />
          <div className={classes.ServiceContainer}>
            <h1>
              <span className={classes.schoolbell}>Was</span> erwartet dich?
            </h1>
            <div className={classes.InfoContainers}>
              <div className={classes.InfoContainer}>
                <h3>
                  Individuelle{" "}
                  <span className={classes["title-highlight"]}>Betreuung</span>
                </h3>
                <h5>
                  <span className={classes.highlight}>
                    Maßgeschneiderte Unterstützung
                  </span>
                  <br />
                  und flexible Anpassung des Unterrichts.
                </h5>
              </div>
              <div className={classes.InfoContainer}>
                <h3>
                  Gezielte{" "}
                  <span className={classes["title-highlight"]}>
                    Vorbereitung
                  </span>
                </h3>
                <h5>
                  Wir konzentrieren uns auf
                  <span className={classes.highlight}>
                    <br />
                    prüfungsrelevante Themen
                  </span>
                  ,
                  <br />
                  um dich optimal vozubereiten
                </h5>
              </div>
              <div className={classes.InfoContainer}>
                <h3>
                  Zeitliche{" "}
                  <span className={classes["title-highlight"]}>
                    Flexibilität
                  </span>
                </h3>
                <h5>
                  bedeutet, dass wir unsere Unterrichtszeiten <br />
                  <span className={classes.highlight}>
                    nach deinem Zeitplan
                  </span>
                  <br />
                  ausrichten.
                </h5>
              </div>
              <div className={classes.InfoContainer}>
                <h3>
                  Fragen?{" "}
                  <span className={classes["title-highlight"]}>
                    Raus damit!
                  </span>
                </h3>
                <h5>
                  Bei offenen Fragezeichen stehe ich auch{" "}
                  <span className={classes.highlight}>direkt über Chat</span>{" "}
                  zur Verfügung
                </h5>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
