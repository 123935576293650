import { useLocation, useNavigate } from "react-router-dom";
import classes from "../../css/Formula.module.css";

export function Footer() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <footer className={classes.footer}>
      <div className={classes["top-footer"]}>
        <h5 className={classes.h5}>
          Unterstützung in <span className={classes.span}>Mathematik</span>?{" "}
          <br />
          Das ist <span className={classes.span}>leicht gemacht</span>!
        </h5>
        <div className={classes["bottom-menu"]}>
          <a
            onClick={() =>
              navigate("/imprint", {
                state: { prevLocation: location.pathname },
              })
            }
          >
            Impressum
          </a>
          <a
            onClick={() =>
              navigate("/privacy-policy", {
                state: { prevLocation: location.pathname },
              })
            }
          >
            Datenschutzerklärung
          </a>
          <a
            onClick={() =>
              navigate("/contact", {
                state: { prevLocation: location.pathname },
              })
            }
          >
            Kontakt
          </a>
          <a href="">Hilfe</a>
        </div>
      </div>

      <div className={classes.copyright}>
        <p>&copy; Omid Sedighi-Mornani 2024</p>
        <p>All rights reserved</p>
      </div>
    </footer>
  );
}
