import { useEffect, useState } from "react";
import classes from "../css/Booking.module.css";
import { format } from "date-fns";

const dayOfWeek = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];

export function DateField({
  uniqueID,
  date = new Date(),
  giveDate = () => {},
  selectedDate,
}) {
  const dateFormatted = format(date, "yyyy-MM-dd");

  // useEffect(() => {
  //   if (checkedValue !== selectedDate) {
  //     setChecked(null);
  //   }
  // }, [selectedDate]);

  return (
    <div className={classes.dateField}>
      <input
        type="radio"
        name="date-field"
        className={classes["date-radio"]}
        checked={dateFormatted == selectedDate}
        value={dateFormatted}
        onChange={() => giveDate(dateFormatted)}
        id={`radio${uniqueID}`}
      />
      <label htmlFor={`radio${uniqueID}`}>{dayOfWeek[date.getDay()]}</label>
      <label htmlFor={`radio${uniqueID}`}>{date.getDate()}</label>
    </div>
  );
}
