import { useState } from "react";
import classes from "../css/Main.module.css";
import validateResponse from "../../utils/requestValidator";

export function TimeslotForm({ handleRefresh }) {
  const [visibility, setVisibility] = useState(false);
  const [timeslot, setTimeslot] = useState(["", "", ""]);

  const json_url = process.env.REACT_APP_FETCHING_SERVER;

  const toggleVisibility = () => setVisibility(!visibility);

  let style = { display: visibility ? "block" : "none" };

  function submit_timeslot() {
    if (timeslot.includes("")) {
      alert("Alle Felder müssen ausgefüllt werden!");
      return;
    }

    fetch(json_url + "/submit-timeslot", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": sessionStorage.getItem("token"),
      },
      body: JSON.stringify(timeslot),
    })
      .then((response) => validateResponse(response).json())
      .then((data) => {
        console.log("Successfully posted timeslot: ", data);
      })
      .catch((error) => {
        console.error("Error whilst posting timeslot: ", error);
      })
      .finally(() => {
        handleRefresh();
        setTimeslot([timeslot[0], "", ""]);
      });
  }

  function changeTimeslotValue(i, value) {
    const newTimeslot = timeslot.slice();
    newTimeslot[i] = value;
    setTimeslot(newTimeslot);
  }

  return (
    <>
      <button
        className={classes.button}
        type="button"
        onClick={toggleVisibility}
      >
        + Timeslot
      </button>
      <div className={classes.overlay} style={style}></div>
      <div className={`${classes.containers}`} style={style}>
        <div
          className={classes["menu-burger"]}
          onClick={toggleVisibility}
        ></div>
        <h2 className={classes.h2}>Timeslot erstellen</h2>

        <label className={classes.label}>Datum:</label>
        <input
          className={classes.input}
          type="date"
          value={timeslot[0]}
          onChange={(e) => changeTimeslotValue(0, e.target.value)}
          required
        />

        <label className={classes.label}>Von:</label>
        <input
          className={classes.input}
          type="time"
          value={timeslot[1]}
          onChange={(e) => changeTimeslotValue(1, e.target.value)}
          required
        />

        <label className={classes.label}>Bis:</label>
        <input
          className={classes.input}
          type="time"
          value={timeslot[2]}
          onChange={(e) => changeTimeslotValue(2, e.target.value)}
          required
        />
        <button className={classes.button} onClick={submit_timeslot}>
          Erstellen
        </button>
      </div>
    </>
  );
}
