import { Table } from "../components/billing-table.js";
import { Select } from "../components/dropdown.js";
import { AppointmentForm } from "../components/appointment-form.js";
import { ServiceForm } from "../components/service-form.js";
import { CustomerInfo } from "../components/customer-info.js";
import { useState } from "react";
import classes from "../css/Main.module.css";
import { useNavigate } from "react-router-dom";

export default function Main() {
  const [studentIDSelected, setStudentID] = useState("");
  const navigate = useNavigate();

  function handleSelection(id) {
    setStudentID(id);
  }

  return (
    <div className={classes.body}>
      <div className={classes.container}>
        <h1>Rechnungserstellung</h1>
        <nav className={classes.nav}>
          <div className={classes["add-buttons"]}>
            <button
              className={classes.button}
              onClick={() => navigate("/formula")}
            >
              + Schüler
            </button>
            <ServiceForm />
            <AppointmentForm />
          </div>

          <div className={classes["action-buttons"]}>
            <button
              className={classes.button}
              onClick={() =>
                navigate("/billing/invoices", {
                  state: { isAuthenticated: true },
                })
              }
            >
              Rechnungen
            </button>
            <button
              className={classes.button}
              onClick={() =>
                navigate("/billing/appointments", {
                  state: { isAuthenticated: true },
                })
              }
            >
              Termine bestätigen
            </button>
            <button
              className={classes.button}
              onClick={() =>
                navigate("/billing/timeslots", {
                  state: { isAuthenticated: true },
                })
              }
            >
              Timeslots
            </button>
          </div>
        </nav>
        <div className={classes["customer-selection"]}>
          <label className={classes.label}>Kunde auswählen:</label>
          <Select onDropdownChange={handleSelection} target={"students"} />
        </div>
        <CustomerInfo value={studentIDSelected} />
        <div className={classes["invoice-items"]}>
          <h2 className={classes.h2}>Rechnungsposten</h2>
          <Table selectedID={studentIDSelected} />
        </div>
      </div>
    </div>
  );
}
