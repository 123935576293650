import { useEffect, useState } from "react";
import classes from "../css/Main.module.css";
import validateResponse from "../../utils/requestValidator";

const json_url = process.env.REACT_APP_FETCHING_SERVER;

export function CustomerInfo({ value = "" }) {
  const [students, setStudents] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(json_url + "/data/students", {
          headers: { "x-api-key": sessionStorage.getItem("token") },
        });
        const result = await validateResponse(response).json();
        setStudents(result);
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (value == "") {
    return (
      <div id="customerInfo" className={classes["customer-info"]}>
        <h2 className={classes.h2}>Kundeninformation</h2>
        <p id="customerDetails">Kunden aus dem Dropdown-Menü auswählen.</p>
      </div>
    );
  } else if (!isNaN(value)) {
    if (loading) {
      return <p>Studentendaten werden geladen...</p>;
    } else if (!students) {
      return <p>Keine Kundendaten verfügbar</p>;
    } else {
      const student = students.find((s) => s.studentID == value);
      return (
        <div id="customerInfo" className={classes["customer-info"]}>
          <h2 className={classes.h2}>Kundeninformation</h2>
          <div id="customerDetails" className={classes.customerDetails}>
            <p>
              <strong>Name:</strong> {student.firstName} {student.lastName}
            </p>
            <p>
              <strong>Adresse:</strong> {student.address}
            </p>
            <p>
              <strong>Email:</strong> {student.email}
            </p>
            <p>
              <strong>Ort:</strong> {student.zip}, {student.city}
            </p>
            <p>
              <strong>Telefon:</strong> {student.phone}
            </p>
          </div>
        </div>
      );
    }
  }

  return <p>NOT VALID STATE!</p>;
}
