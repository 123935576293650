import classes from "../../css/Legal.module.css";
import { ArrowLeft } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";

export default function Imprint() {
  const location = useLocation();
  const navigate = useNavigate();

  function navigateBack() {
    if (location.state) {
      navigate(location.state.prevLocation);
    } else {
      navigate("/");
    }
  }

  return (
    <div className={`${classes.Imprint} ${classes.LegalContainer}`}>
      <h4>Impressum</h4>
      <div className={classes.companyInformation}>
        <p>Omid Sedighi-Mornani</p>
        <p>Am Sonnenhügel 15</p>
        <p>97320 Mainstockheim</p>
        <p>Deutschland</p>
      </div>
      <div className={classes.companyInformation}>
        <p>
          <b>Telefon:</b> +49 179 1392716
        </p>
        <p>
          <b>Email-Adresse:</b> osedighimornani@gmail.com
        </p>
        <p>
          <b>USt.-ID:</b> DE369216770{" "}
        </p>

        <div className={classes.backRef}>
          <ArrowLeft size={16} />
          <a onClick={navigateBack}>Zurück</a>
        </div>
      </div>
    </div>
  );
}
